<template>
  <Wrapper class="subpage page-profile">
    <SplitView>
      <aside slot="sidebar" class="is-paddingless">
        <div class="profile-card">
          <ProfilePicture
            :userId="Number(user.id)"
            :name="fullName"
            size="normal"
            round
            class="push-bottom">
          </ProfilePicture>

          <p class="title is-5 is-marginless text-is-ellipsis" style="max-width: 80%;">
            <span>{{ fullName }}</span>
          </p>

          <p
            :title="roles"
            class="subtitle is-6 is-marginless has-text-centered text-is-ellipsis"
            style="width: 75%;">
            {{ roles }}
          </p>

          <!-- <span v-if="hasPro" class="tag is-primary push-top-xs">Pro</span> -->
        </div>

        <div class="menu">
          <p class="menu-label">{{ $t('profile.account.title') }}</p>

          <ul class="menu-list">
            <li>
              <RouterLink to="/profile">
                <span>{{ $t('profile.account.information.title') }}</span>
              </RouterLink>
            </li>

            <li>
              <RouterLink to="/profile/subscription">
                <span>{{ $t('general.myAccount') }}</span>
              </RouterLink>
            </li>

            <li>
              <RouterLink to="/profile/about">
                <span>{{ $t('profile.account.whoAreYou.title') }}</span>
              </RouterLink>
            </li>

            <li>
              <RouterLink to="/profile/interests">
                <span>{{ $t('profile.account.interests.title') }}</span>
              </RouterLink>
            </li>

            <li v-if="hasSubscription">
              <RouterLink to="/profile/manage">
                <span>{{ $t('profile.account.manage.title') }}</span>
              </RouterLink>
            </li>
          </ul>

          <p class="menu-label">{{ $t('profile.settings.title') }}</p>

          <ul class="menu-list">
            <li>
              <RouterLink :to="{ name: 'profileEmail' }">
                <span>{{ $t('profile.settings.email.title') }}</span>
              </RouterLink>
            </li>

            <li>
              <RouterLink :to="{ name: 'profilePassword' }">
                <span>{{ $t('profile.settings.password.title') }}</span>
              </RouterLink>
            </li>

            <li v-if="hasSubscription">
              <RouterLink :to="{ name: 'profilePayment' }">
                {{ $t('profile.settings.payment.title') }}
              </RouterLink>
            </li>
          </ul>

          <p class="menu-label">WeHunt</p>

          <ul class="menu-list">
            <li>
              <RouterLink to="/profile/wehunt/notifications">
                <span>{{ $t('profile.settings.notifications.title') }}</span>
              </RouterLink>
            </li>

            <li>
              <RouterLink to="/profile/wehunt/newsletter">
                <span>{{ $t('profile.settings.newsletter.title') }}</span>
              </RouterLink>
            </li>
          </ul>
        </div>
      </aside>

      <div v-if="hasActiveCampaign" slot="wrapper">
        <Titlebar class="position-is-absolute">
          <h5 slot="title" class="title is-5 is-marginless has-text-white">{{ $t('profile.title') }}</h5>
        </Titlebar>

        <section class="hero campaign-hero is-light is-medium">
          <div class="hero-body">
            <div class="container has-text-centered">
              <h1 class="title is-1 has-text-white">{{ $t('campaign.title') }}</h1>
              <h2 class="subtitle is-4 has-text-white">{{ $t('campaign.subtitle') }}</h2>

              <div class="buttons is-centered are-medium">
                <button
                  :class="{ 'is-loading': isLoading }"
                  class="button is-white is-rounded"
                  @click="onBuy">
                  {{ $t('campaign.getPro') }}
                </button>

                <a
                  href="https://wehuntapp.com/pro"
                  target="_blank"
                  class="button is-text is-rounded">
                  {{ $t('campaign.readMore') }}
                </a>
              </div>

              <small class="has-text-white push-top" v-html="$t('campaign.infoText')"></small>
            </div>
          </div>
        </section>

        <div class="default-spacing">
          <RouterView />
        </div>
      </div>

      <div v-else slot="wrapper" class="default-spacing">
        <Titlebar class="is-paddingless push-bottom-xs">
          <h5 slot="title" class="title is-5 is-marginless">{{ $t('profile.title') }}</h5>
        </Titlebar>

        <RouterView />
      </div>
    </SplitView>
  </Wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { SUBSCRIPTION_TYPE_STANDARD } from '@/modules/subscription/store/subscriptionStore'

export default {
  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user/data',
      profile: 'profile/getProfile',
      hasSubscription: 'subscription/hasSubscription',
      hasActiveCampaign: 'campaign/isActive'
    }),

    fullName () {
      return this.user !== null ? `${this.user.firstName} ${this.user.lastName}` : ''
    },

    roles () {
      const roles = []

      Object.keys(this.profile.roles).forEach(role => {
        if (this.profile.roles[role]) {
          roles.push(this.$t(`profile.account.whoAreYou.${role}`))
        }
      })

      return roles.join(', ')
    }
  },

  methods: {
    ...mapActions({
      checkout: 'subscription/checkout',
      purchase: 'subscription/purchase'
    }),

    async onBuy () {
      this.isLoading = true

      try {
        const session = await this.purchase({
          type: SUBSCRIPTION_TYPE_STANDARD,
          returnPath: this.$route.path
        })

        this.checkout(session)
      } catch (error) {
        this.isLoading = false
        this.$notification.danger(this.$t('subscription.current.pro.purchaseErrorMessage'))
      }
    }
  }
}
</script>

<style lang="scss">
.campaign-hero {
  background: var(--noise), linear-gradient(141deg, #fd746c, #ff9068, #ffb88c);

  .ie & {
    background: linear-gradient(141deg, #ff9068, #ffb88c);
  }

  .hero-body {
    padding-top: 5.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .title {
    font-family: "Roboto", sans-serif;
    font-size: 4rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-shadow: 2px 3px 3px rgba(218, 108, 70, 0.4);
  }

  .subtitle,
  small {
    text-shadow: 1px 1px 1px rgba(218, 108, 70, 0.15);
  }

  .button {
    font-family: "Roboto", sans-serif;
    font-weight: 500;

    &.is-text {
      color: white;
      text-decoration: none;

      &:hover {
        color: #333;
      }
    }
  }
}

.page-profile {
  display: flex;
  flex-direction: column;

  .container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .split-view-sidebar {
    background: white;

    .menu {
      padding: 0.75rem;
    }
  }

  .profile-card {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
